@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap");
body {
  font-family: "Poppins, sans-serif";
  background-color: "#E8E8E8";
}

option {
  color: black;
}
::-webkit-scrollbar {
  width: 6px !important;
  height: 6px !important;
}

::-webkit-scrollbar-track {
  background: hsla(0, 0%, 100%, -1);
}

::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
}
